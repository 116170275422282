/* eslint-disable @typescript-eslint/no-explicit-any */
import config from '../../config.json';

document.addEventListener('DOMContentLoaded', () => {
  const loadableAtoms: Promise<unknown>[] = config.atoms.map(
    (loadable: string) => {
      const element = document.querySelector(`.js-${loadable}`);
      if (element) {
        return import(
          /* webpackChunkName: "[request]" */ `@templates/stories/atoms/${loadable}/${loadable}.ts`
        );
      }
      return Promise.resolve(null);
    },
  );

  const loadableComps: Promise<unknown>[] = config.components.map(
    (loadable: string) => {
      const element = document.querySelector(`.js-${loadable}`);
      if (element) {
        return import(
          /* webpackChunkName: "[request]" */ `@templates/stories/components/${loadable}/${loadable}.ts`
        );
      }
      return Promise.resolve(null);
    },
  );

  const loadableBlocks: Promise<unknown>[] = config.blocks.map(
    (loadable: string) => {
      const element = document.querySelector(`.js-${loadable}`);
      if (element) {
        return import(
          /* webpackChunkName: "[request]" */ `@templates/stories/blocks/${loadable}/${loadable}.ts`
        );
      }
      return Promise.resolve(null);
    },
  );

  // Load all components and blocks
  Promise.all([...loadableAtoms, ...loadableComps, ...loadableBlocks])
    .then((loadedModules: (unknown | null)[]) => {
      loadedModules
        .filter((v) => v !== null)
        .forEach((loadedModule: any) => {
          if (loadedModule) {
            loadedModule.default();
          }
        });
    })
    .catch((err: Error) => {
      throw new Error(err.message);
    });
});
